<template>
  <div class="layout-header-section d-lg-flex d-block justify-content-between">
    <div class="header-label col-lg-5 col-12">Hosts Settings</div>
  </div>
  <div
    class="layout-box-content-section mb-3"
    style="height: calc(-157px + 100vh)"
  >
    <div class="row pb-5">
      <div class="col-lg-4 col-md-4 col-12">
        <div class="custom-form-group">
          <label class="form-label"
            >Select Mode<span class="text-danger">*</span></label
          >
          <Multiselect
            v-model="selectedMode"
            :options="mode"
            :searchable="true"
            label="label"
            placeholder="Select Mode"
            class="multiselect-custom text-capitalize"
            :canClear="true"
            :closeOnSelect="true"
            noOptionsText="No Result Found"
            :object="true"
            :createTag="false"
          />
          <div class="custom-error" v-if="v$.selectedMode.$error">
            {{ v$.selectedMode.$errors[0].$message }}
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-4 col-12" v-if="selectedMode.value == '2'">
        <div class="custom-form-group">
          <label class="form-label"
            >Select User<span class="text-danger">*</span></label
          >
          <Multiselect
            v-model="selectedUserList"
            :options="userLists"
            :searchable="true"
            label="label"
            placeholder="Select User"
            class="multiselect-custom text-capitalize"
            :canClear="true"
            :closeOnSelect="true"
            noOptionsText="No Result Found"
            :object="true"
            mode="tags"
            :createTag="false"
          />
          <div class="custom-error" v-if="v$.userLists.$error">
            {{ v$.userLists.$errors[0].$message }}
          </div>
        </div>
      </div>
    </div>
    <div class="row pt-5">
      <div class="col-lg-12 col-md-12 col-12 text-center">
        <button
          type="button"
          class="btn modal-bulk-next-btn"
          @click="submitHostSetting()"
          :disabled="btnloader"
        >
          <span v-if="!btnloader">Submit</span>
          <div
            class="spinner-border text-light custom-spinner-loader-btn"
            role="status"
            v-if="btnloader"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
        </button>
      </div>
    </div>
  </div>
</template>
  
<script>
import { required, helpers } from "@vuelidate/validators";
import ApiService from "../../../service/ApiService";
import useValidate from "@vuelidate/core";

export default {
  data() {
    return {
      v$: useValidate(),
      userValues: "",
      btnloader: false,
      userLists: [],
      mode: [
        {
          label: "Auto Approve",
          value: "1",
        },
        {
          label: "Manually Approve",
          value: "2",
        },
      ],
      selectedMode: "",
      selectedUserList: [],
    };
  },
  ApiService: null,
  created() {
    this.ApiService = new ApiService();
    this.getAdminUsers();
  },
  validations() {
    return {
      userLists: {
          required: helpers.withMessage("Please select user", required),
        },
        selectedMode: {
          required: helpers.withMessage("Please select setting", required),
        },
    };
  },
  mounted() { 
    this.client_info = JSON.parse(localStorage.client_info);
    if(this.client_info){
      // this.selectedUserList = this.userLists.filter(user => this.client_info.maa48.includes(user.value));
      this.selectedMode = this.mode.find(mode => mode.value === this.client_info.maa47);
    }
    // console.log("this.client_info.maa48",this.client_info.maa48);
    // console.log("this.selectedUserList",this.selectedUserList);
    // console.log("this.selectedMode",this.selectedMode);

  },
    watch: {
      userLists(){
      this.selectedUserList = this.userLists.filter(user => this.client_info.maa48.includes(user.value));
      }
  },
  methods: {
    getAdminUsers() {
      this.ApiService.getAdminUsers().then((data) => {
        if (data.status == 200) {
          this.userLists = data.data;
          this.loading = false;
        }
      });
    },
    submitHostSetting() {
      this.v$.$validate();
      if (!this.v$.$error) {
        var formData = new FormData();
        formData.append("maa47", this.selectedMode.value);
        const valueList = this.selectedUserList.map((d)=>d.value);
        const values = JSON.stringify(valueList);
        formData.append("maa48",values);
        this.client_info.maa47 = this.selectedMode.value;
        this.client_info.maa48 = values;
        localStorage.setItem("client_info", JSON.stringify(this.client_info));
        this.ApiService.updateHostApprovalSetting(formData).then((data) => {
          if (data.status == 200) {
            var successMsg = data.message;
            this.$toast.open({
              message: successMsg,
              type: "success",
              duration: 3000,
              position: "top-right",
            });
          } else {
            var errorMsg = data.message;
            this.$toast.open({
              message: errorMsg,
              type: "error",
              duration: 3000,
              position: "top-right",
            });
          }
        });
      } else {
        this.$error;
      }
    },
  },
};
</script>
  
<style></style>